// $font: Futura LT;
$font-family: "MicroSquare";
$font-family-title: "Sequel100Black-95";
$font-family-button: "Sequel100Black-95";

@import "./assets/fonts/stylesheet.css";
@import "../node_modules/sme-theme/src/theme.scss";
@import "./assets/scss/jukebox-display.scss";

@include media-breakpoint-max(md) {
  .Container {
    margin: 0 auto;
  }
}

.qr-reader .vsc-controller+div {
  box-shadow: rgba(color(primary), 0.5) 0px 0px 0px 5px inset!important;
}
.Page.menubar-side {
  margin-bottom: 4rem;
}
.Menubar {
  position: fixed;
  top: 0;
  width: 260px;
  bottom: 0;
  left: 0;
  border-right: 1px solid color(white);
  overflow: auto;
  // background-color: color(primary);
  .icon-cancel {
    position: absolute;
    top: 20px;
    right: 20px;
  }
  .menu-content {
    .Button {
      background: transparent;
      border: transparent;
      color: transparent;
      display: block;
      width: 100%;
      text-align: left;
      padding: 0.6em 0;
      margin: 0;
      color: color(white);
      text-transform: initial;
      font-size: font-size(lg);
      // -webkit-text-stroke: .03em color(black);

      &.active, &:hover {
        color: color(primary);
      }

      .icon-arrow-down {
        display: inline-block;
        font-size: 0.65em;
        margin-left: 10px;
        transition: transform .3s;
      }
      &.active {
        .icon-arrow-down {
          transform: rotate(180deg);
        }
      }
    }
    button.Button {
      &.active {
        color: color(white);
        &:hover {
          color: color(primary);
        }
      }
    }
    .subitem {
      font-size: font-size(lg);
      font-family: $font-family-text;
      margin-left: space-size(md);
    }
  }

  header, header .Icon {
    color: color(white);
  }
  .Titlebar {
    border-color: color(white);
  }

  &.show {
    left: 0;
    width: 100vw;
  }
  @include media-breakpoint-min(md) {

    &.show {
      width: 260px!important;
    }
  }
  @include media-breakpoint-max(md) {
    position: fixed;
    // background: color(black);
    top: 0;
    width: 100vw;
    bottom: 0;
    left: -100vw;
    z-index: 200;
    transition: .3s;

    background-color: color(black);
    .menu-content {
      // .Button {
      //   color: color(black);
      //   // font-size: title-size(lg);
      //   // -webkit-text-stroke: .03em color(black);
      //
      //   &.active {
      //     color: color(black);
      //   }
      // }
    }
  };
}


.menubar-side, .menubar-side.loading .page-loading {
  margin-left: 300px;

  @include media-breakpoint-max(md) {
    margin-left: 0;
  }
}
.menubar-button {
  visibility: hidden!important;
  @include media-breakpoint-max(md) {
    visibility: visible!important;
  }
}


// TO theme
.Button {
  &.error {
    color: color(white);
  }
}
[class*=Input-].after {
  .Button {
    margin-top: -5px!important;
  }
}
.Input-textarea {
  textarea {
    // resize: vertical;
    resize: none;
    min-height: 100px;
  }
  &.no-resize {
    textarea {
      resize: none;
    }
  }
  &.textarea-sm {
    textarea {
      height: 100px;
    }
  }
}
.Image {
  &.square, &.rounded {
    overflow: hidden;
    display: block;
    object-fit: cover;
    @include aspect-ratio(1,1);
    img {
      object-fit: cover;
      height: 100%;
      width: 100%;
      @include position-abslute();
    }
  }
}

.Table tbody td {
  height: 40px;
}
.table-hover-show {
  opacity: 0;
}
tr:hover .table-hover-show {
  opacity: 1;
}
.Table td > span {
  word-break: break-word;
}
