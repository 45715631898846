$animation-speed: 1s;

.page-jukebox-display {
  height: 100%;
  display: flex;
  // align-items: center;
  justify-content: center;
  position: fixed;
  top: 10vh;
  right: 0;
  bottom: 0;
  left: 0;

  .jukebox-video-bg {
    position: fixed;
    top:0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;

    &:after {
      content: "";
      position: fixed;
      top:0;
      right: 0;
      bottom: 0;
      left: 0;
      // background-color: rgba(0,0,0,.5);
      pointer-events: none;
    }
    video {
      width: 100%;
      height: 100%;
    }
  }

  .jukebox-display-title {
    font-size: 3.8vw;
    text-align: center;
    // padding-top: 6.8vw;
  }
  .jukebox-display-grid {
    width: 58vw;
    overflow: hidden;
    margin: 3vw auto 0;
  }
  .jukebox-display-cell {
    float: left;
    width: 23.1vw;
  }
  .jukebox-display-dj {
    overflow: hidden;
    .jukebox-display-image-flip {
      float: left;
      width: 5vw;
      height: 5vw;
      border-radius: 100%;
      overflow: hidden;
    }
    &>.Text {
      float: left;
      font-size: 2.4vw;
      margin-top: 0.4vw;
      font-weight: normal;
      width: 100%;
      text-align: center;
    }
    .jukebox-display-text-flip {
      float: left;
      font-size: 1.9vw;
      margin-top: 0;
      width: 16.2vw;
      height: 2.3vw;
      width: 100%;
    }
    .jukebox-display-text-flip .Text {
      font-size: 1.9vw;
      margin: 0;
      padding: 0;
      text-align: center;
      width: 100%;
    }
  }
  .jukebox-display-current-album {
    .jukebox-display-image-flip {
      margin: .5vw 0 1.5vw;
    }
    .jukebox-display-image-flip img {
      width: 100%;
      height: 100%;
    }
    .Title {
      font-size: 1.7vw;
      text-align: center;
      margin: 0;
    }
    .jukebox-display-music-artist{
      font-size: 1.1vw;
      line-height: 1.3vw;
    }

    .jukebox-display-text-flip {
      margin-top: 0.4vw;
      width: 100%;
      height: 3vw;
    }
    .jukebox-display-text-flip + .jukebox-display-text-flip {
      margin-top: 0;
      width: 100%;
      height: 1.6vw;
    }
  }
}


.jukebox-display-cell + .jukebox-display-cell {
  float: right;
  &>.Text {
    font-size: 2.1vw;
    margin-top: 3.6vw;
  }
}
.jukebox-display-playlist {
  margin-top: 1.5vw;
  overflow: hidden;
  height: 24vw;
}
.jukebox-display-playlist-item {
  overflow: hidden;
  border-bottom: 1px solid #8A8A8A;
  padding-bottom: 1.1vw;
  margin-bottom: 1.1vw;
  transition: 0s;
  display: flex;
  align-items: center;
  &:last-child {
    border: 0;
  }
  &>.Image {
    width: 6vw;
    height: 6vw;
    float: left;
    margin-right: 0.9vw;
  }
  &>span {
    float: left;
    display: block;
    width: 16.2vw;
    &>.Image {
      float: left;
      width: 3vw;
      height: 3vw;
      border-radius: 100%;
      overflow: hidden;
    }
    &>.Image + .Text {
      line-height: 3.3vw;
      padding-left: 1vw;
    }
    & .Text {
      font-size: 1.2vw;
    }
    & .Text + .Text {
      margin-top: 0.5vw;
    }
    & .Text + .Text + .Text {
      margin-top: 0vw;
    }
  }
}



.jukebox-display-image-flip {
  position: relative;
  perspective: 1000px;
  .jukebox-display-image-flip-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0s;
    transform-style: preserve-3d;
  }
  .Image {
    // position: absolute;
    // width: 100%;
    // height: 100%;
    backface-visibility: hidden;
  }
  .Image + .Image {
    position: absolute;
    top:0;right:0;bottom:0;left:0;
    transform: rotateY(180deg);
  }
}

.jukebox-display-text-flip {
  position: relative;
  perspective: 1000px;
  .jukebox-display-text-flip-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0s;
    transform-style: preserve-3d;
  }
  .Text, .Title {
    position: absolute;
    top:0;right:0;bottom:0;left:0;
    backface-visibility: hidden;
    margin: 0;
  }
  .Text + .Text, .Title + .Title {
    transform: rotateX(180deg);
  }
}

.changing-music {
  .jukebox-display-image-flip {
    .jukebox-display-image-flip-inner {
      transition: $animation-speed;
      transform: rotateY(180deg);
    }
  }
  .jukebox-display-playlist-item {
    transition: .7s;
    transform: translateY(-8.6vw);
    &:nth-child(2) {
      transition-delay: .1s;
    }
    &:nth-child(3) {
      transition-delay: .2s;
    }
    &:nth-child(4) {
      transition-delay: .3s;
    }
  }
  .jukebox-display-text-flip {
    .jukebox-display-text-flip-inner {
      transition: $animation-speed;
      transform: rotateX(180deg);
    }
  }
}





._TEMP-layoutRef {
  width: 100%;
  position: absolute;
  opacity: 0;
  pointer-events: none;
  margin-left: 1.4vw;
}
