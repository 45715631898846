@font-face {
    font-family: 'MicroSquare';
    src: url('MicroSquare-Bold.woff2') format('woff2'),
        url('MicroSquare-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Sequel100Black-95';
    src: url('Sequel100Black-95.woff2') format('woff2'),
        url('Sequel100Black-95.woff') format('woff');
    font-weight: 900;
    font-style: normal;
}

